<template>
  <div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn outlined class="ma-2" color="grey darken-2" @click="setToday">
        Hoje
      </v-btn>
      <v-autocomplete
        v-model="type"
        :items="types"
        item-text="text"
        item-value="value"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Tipos"
      ></v-autocomplete>
      <v-autocomplete
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Dias da Semana"
        class="ma-2"
      ></v-autocomplete>
      <v-btn
        outlined
        class="ma-2"
        color="grey darken-2"
        @click="atualizarListaProgramacoesRegiao()"
        >Atualizar
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @change="getEvents"
      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="v-event-draggable" v-html="eventSummary()"></div>
          <!-- <div class="ma-2">
            <b>Restante:</b>
            <div class="mx-1 mb-2">
              <span>Peso:</span>
              <br />
              <span class="mx-3">{{ qt_peso(event.item) }}</span>
              <br />
              Caminhões:
              <br />
              <span class="mx-3">{{ qt_caminhoes(event.item) }}</span>
            </div>
            <hr />
          </div> -->
          <!-- <table style="width: 90%;background: white;color: black;margin: 10px;" border="1px">
             <thead>
                <tr>
                  <th style="padding: 1px;">Designado</th>
                  <th style="padding: 1px;">Agendados</th>
                  <th style="padding: 1px;">Restante</th>
                </tr>
             </thead>
              <tbody>
                <tr>
                    <td style="padding: 1px;">{{ event.item.qt_caminhoes ? event.item.qt_caminhoes : '0' }}</td>
                    <td style="padding: 1px;">{{ quantidade_atendida(event.item) }}</td>
                    <td style="padding: 1px;">{{ quantidade_restante_caminhoes(event.item) }}</td>
                  </tr>
              </tbody>
            </table> -->
          <b>Caminhões atendidos:</b>
          <table
            style="width: 90%; background: white; color: black; margin: 10px"
            border="1px"
          >
            <thead>
              <tr>
                <th style="padding: 1px">Designado</th>
                <th style="padding: 1px">Atendida</th>
                <th style="padding: 1px">Restante</th>
                <th style="padding: 1px">Performace</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ event.item.qt_caminhoes }}
                </td>
                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ event.item.qt_peso }} TON
                </td>

                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ quantidade_atendida(event.item) }}
                </td>
                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ quantidade_restante_caminhoes(event.item) }}
                </td>
                <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                  {{ performace_caminhoes(event.item) }}
                </td>

                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ quantidade_agendada_peso(event.item) }} TON
                </td>
                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ quantidade_restante_peso(event.item) }} TON
                </td>
                <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                  {{ performace_caminhoes_peso(event.item) }} TON
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li class="font-weight-black">
              {{
                event.item.programacao_fornecedor.programacao_unidade
                  .tipo_operacao
                  ? event.item.programacao_fornecedor.programacao_unidade
                      .tipo_operacao.descricao
                  : 'Sem operação!'
              }}
            </li>

            <li>
              {{
                event.item.programacao_fornecedor.programacao_unidade.fornecedor
                  ? event.item.programacao_fornecedor.programacao_unidade
                      .fornecedor.fornecedor_trade_name
                  : 'Sem fornecedor!'
              }}
            </li>
            <li
              v-if="
                event.item.programacao_fornecedor.programacao_unidade.terminal
              "
            >
              {{
                event.item.programacao_fornecedor.programacao_unidade.terminal
                  .trade_name
              }}
            </li>
            <li
              v-if="
                event.item.programacao_fornecedor.programacao_unidade.produto
              "
            >
              {{
                event.item.programacao_fornecedor.programacao_unidade.produto
                  .descricao
              }}
            </li>
            <li
              v-if="
                event.item.programacao_fornecedor.programacao_unidade
                  .sub_produto
              "
            >
              {{
                event.item.programacao_fornecedor.programacao_unidade
                  .sub_produto.descricao
              }}
            </li>
            <li>Peso: {{ event.item.qt_peso ? event.item.qt_peso : '0' }}</li>
            <li>
              Caminhões:
              {{ event.item.qt_caminhoes ? event.item.qt_caminhoes : '0' }}
            </li>
          </ul>
        </template>
      </v-calendar>
    </v-sheet>
    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-container>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form>
              <v-card-title>
                <span class="headline">Programação Cliente</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.proprietario
                        "
                        label="Gate/Unidade"
                        :items="[
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.proprietario,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-boom-gate"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.fornecedor
                        "
                        label="Fornecedor"
                        :items="[
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.fornecedor,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-equalizer"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.tipo_operacao
                        "
                        label="Tipo de Operação"
                        :items="[
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.tipo_operacao,
                        ]"
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-clipboard-flow-outline"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.terminal
                        "
                        label="Terminal"
                        :items="[
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.terminal,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-alpha-t-box-outline"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                  <v-row>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.tipo_controle
                        "
                        label="Tipo de controle"
                        :items="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.tipo_controle
                            ? [
                                programacaoRegiao.programacao_fornecedor
                                  .programacao_unidade.tipo_controle,
                              ]
                            : []
                        "
                        item-text="descricao"
                        item
                        prepend-icon="mdi-clipboard-alert-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.modalidades
                        "
                        label="Tipos de Veículo"
                        :items="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.modalidades
                        "
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.carrocerias
                        "
                        label="Tipos de Carrocerias"
                        :items="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.carrocerias
                        "
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.observacao
                        "
                        :value="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.observacao
                        "
                        label="Observação"
                        prepend-icon="mdi-note-text-outline"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.produto
                        "
                        label="Produto"
                        :items="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.produto
                            ? [
                                programacaoRegiao.programacao_fornecedor
                                  .programacao_unidade.produto,
                              ]
                            : []
                        "
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-box-outline"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.sub_produto
                        "
                        :items="
                          programacaoRegiao.programacao_fornecedor
                            .programacao_unidade.sub_produto
                            ? [
                                programacaoRegiao.programacao_fornecedor
                                  .programacao_unidade.sub_produto,
                              ]
                            : []
                        "
                        item-text="descricao"
                        label="Sub Produto"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-circle-outline"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="3" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoRegiao.programacao_fornecedor.regiao
                        "
                        label="Região"
                        :items="[
                          programacaoRegiao.programacao_fornecedor.regiao,
                        ]"
                        item-text="sigla"
                        item-value="public_id"
                        prepend-icon="mdi-home-city"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoRegiao.programacao_fornecedor.tipos_frete
                        "
                        :items="
                          programacaoRegiao.programacao_fornecedor.tipos_frete
                        "
                        item-text="descricao"
                        item-value="id"
                        label="Tipo de Frete"
                        prepend-icon="mdi-truck"
                        chips
                        multiple
                        return-object
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="programacaoRegiao.cliente"
                        label="Cliente"
                        :items="[programacaoRegiao.cliente]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        prepend-icon="mdi-account"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-autocomplete
                        readonly
                        v-model="programacaoRegiao.pedidos"
                        :items="programacaoRegiao.pedidos"
                        item-text="numero_pedido"
                        item-value="public_id"
                        label="Pedidos"
                        prepend-icon="mdi-ticket-confirmation-outline"
                        chips
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data inicial:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(
                              new Date(
                                programacaoRegiao.programacao_fornecedor.programacao_unidade.data_inicio
                              )
                            )
                          "
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data final:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(
                              new Date(
                                programacaoRegiao.programacao_fornecedor.programacao_unidade.data_fim
                              )
                            )
                          "
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>

                <v-container>
                  <v-card-title v-if="canCreate">
                    <span class="headline">Distribuir nova cota</span>
                  </v-card-title>
                  <v-row v-if="canCreate">
                    <v-col cols="11">
                      <v-row>
                        <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                          <v-autocomplete
                            v-model="editedItem.transportadora"
                            label="Transportadora"
                            :items="transportadoras"
                            :item-text="
                              (item) =>
                                item.business_name +
                                ' - ' +
                                `${item.cnpj != null ? item.cnpj : item.cpf}`
                            "
                            item-value="public_id"
                            hint="Selecione o transportador."
                            persistent-hint
                            prepend-icon="mdi-account"
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                          <v-text-field
                            type="number"
                            v-model="editedItem.qt_peso"
                            label="Toneladas"
                            :max="qt_peso(programacaoRegiao)"
                            :min="0"
                            :disabled="qt_peso(programacaoRegiao) == 0"
                            prepend-icon="mdi-weight"
                            hint="Indique quantas Toneladas será programada."
                            persistent-hint
                            :rules="[
                              () =>
                                editedItem.qt_peso <=
                                  qt_peso(programacaoRegiao) ||
                                'Quantidade de toneladas é maior que a quantidade permitida.',
                            ]"
                          >
                            <template v-slot:label>
                              Restante: {{ qt_peso(programacaoRegiao) }}
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                          <v-text-field
                            type="number"
                            v-model="editedItem.qt_caminhoes"
                            label="Caminhões"
                            :max="qt_caminhoes(programacaoRegiao)"
                            :min="0"
                            :disabled="qt_caminhoes(programacaoRegiao) == 0"
                            prepend-icon="mdi-truck-check-outline"
                            hint="Indique quantos Caminhões será programado."
                            persistent-hint
                            :rules="[
                              () =>
                                editedItem.qt_caminhoes <=
                                  qt_caminhoes(programacaoRegiao) ||
                                'Quantidade de caminhões é maior que a quantidade permitida.',
                            ]"
                          >
                            <template v-slot:label>
                              Restante:
                              {{
                                quantidade_restante_caminhoes_a_distribuir(
                                  programacaoRegiao
                                )
                              }}
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        block
                        color="info"
                        class="text-capitalize"
                        @click="addProgramacao"
                        :disabled="
                          (qt_peso(programacaoRegiao) <= 0 &&
                            qt_caminhoes(programacaoRegiao) <= 0) ||
                          editedItem.qt_peso > qt_peso(programacaoRegiao) ||
                          editedItem.qt_caminhoes >
                            qt_caminhoes(programacaoRegiao)
                        "
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <br />
                  <div v-if="programacaoRegiao.programacoes_cliente.length > 0">
                    <v-divider></v-divider>
                    <v-card-title>
                      <span class="headline">Editar distribuição de cotas</span>
                    </v-card-title>
                    <v-row
                      v-for="(
                        programacao, i
                      ) in programacaoRegiao.programacoes_cliente"
                      v-bind:key="i"
                    >
                      <v-col cols="11">
                        <v-row>
                          <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="transportadora"
                              rules="required"
                            >
                              <v-autocomplete
                                v-model="
                                  programacaoRegiao.programacoes_cliente[i]
                                    .transportadora
                                "
                                label="Transportadora"
                                :items="transportadoras"
                                :error-messages="errors"
                                :item-text="
                                  (item) =>
                                    item.business_name +
                                    ' - ' +
                                    `${
                                      item.cnpj != null ? item.cnpj : item.cpf
                                    }`
                                "
                                item-value="public_id"
                                hint="Selecione o transportador."
                                persistent-hint
                                prepend-icon="mdi-account"
                                required
                                return-object
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="qt_peso"
                              rules="required"
                            >
                              <v-text-field
                                type="number"
                                v-model="
                                  programacaoRegiao.programacoes_cliente[i]
                                    .qt_peso
                                "
                                label="Toneladas"
                                :max="
                                  programacaoRegiao.programacoes_cliente[i]
                                    .qt_peso *
                                    1 +
                                  qt_peso(programacaoRegiao)
                                "
                                :min="0"
                                :disabled="programacaoRegiao.qt_peso == 0"
                                prepend-icon="mdi-weight"
                                :error-messages="errors"
                                hint="Indique quantas Toneladas será programada."
                                persistent-hint
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="qt_caminhoes"
                              rules="required"
                            >
                              <v-text-field
                                type="number"
                                v-model="
                                  programacaoRegiao.programacoes_cliente[i]
                                    .qt_caminhoes
                                "
                                label="Caminhões"
                                :max="
                                  programacaoRegiao.programacoes_cliente[i]
                                    .qt_caminhoes *
                                    1 +
                                  qt_caminhoes(programacaoRegiao)
                                "
                                :min="0"
                                :disabled="programacaoRegiao.qt_caminhoes == 0"
                                prepend-icon="mdi-truck-check-outline"
                                :error-messages="errors"
                                hint="Indique quantos Caminhões será programado."
                                persistent-hint
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="1">
                        <v-btn
                          block
                          color="error"
                          class="text-capitalize"
                          @click="removeProgramacao(i)"
                          ><v-icon>mdi-delete</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-if="canCreate">
                    <v-col cols="12" md="12" lg="6">
                      <label>Replicar:</label>
                      <br />
                      <v-btn-toggle v-model="replicar" multiple>
                        <v-btn :disabled="!replicavel(DAYS.PRIMEIRO)">
                          {{ texto(DAYS.PRIMEIRO, programacaoRegiao) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SEGUNDO)">
                          {{ texto(DAYS.SEGUNDO, programacaoRegiao) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.TERCEIRO)">
                          {{ texto(DAYS.TERCEIRO, programacaoRegiao) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.QUARTO)">
                          {{ texto(DAYS.QUARTO, programacaoRegiao) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.QUINTO)">
                          {{ texto(DAYS.QUINTO, programacaoRegiao) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SEXTO)">
                          {{ texto(DAYS.SEXTO, programacaoRegiao) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SETIMO)">
                          {{ texto(DAYS.SETIMO, programacaoRegiao) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <div>
                      <router-link
                        v-if="
                          !(
                            qt_peso(programacaoRegiao) == 0 &&
                            qt_caminhoes(programacaoRegiao) == 0
                          ) &&
                          editavel &&
                          notSuspended &&
                          !invalid
                        "
                        :to="{
                          name: 'NovoAgendamento',
                          params: {
                            ...paramsToAgendamento(programacaoRegiao),
                          },
                        }"
                      >
                        <v-btn color="primary" small>Novo Agendamento</v-btn>
                      </router-link>
                      <v-btn
                        small
                        class="mx-3"
                        @click="detalhesDistribuicao(programacaoRegiao)"
                      >
                        Agendamentos
                      </v-btn>
                    </div>
                    <v-btn
                      small
                      :disabled="invalid || !editavel || !notSuspended"
                      @click="saveAll"
                    >
                      Salvar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
      <v-overlay :value="carregaDetalhes">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :title="title"
        :headers="headersDetalhes"
        :download="download"
        @close="() => (detalhar = false)"
      />
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  paramsToAgendamento,
  contabilizadores,
  pesquisa,
} from '@/mixins/cotas.js'
import { replicacao } from '@/mixins/replicacao.js'
import { DAYS, date2iso } from '@/mixins/days.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

export default {
  name: 'CalendarioProgramacaoCliente',
  mixins: [paramsToAgendamento, contabilizadores, pesquisa, replicacao],
  components: {
    DetalhesProgramacao,
  },
  data() {
    return {
      detalhes: [],
      title: {},
      download: {
        type: '',
        public_id: '',
      },
      detalhar: false,
      headersDetalhes: [
        {
          text: 'Veículo',
          value: 'veiculo',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          text: 'Liberado Em',
          value: 'saida_do_patio',
        },
        {
          text: 'Tempo em patio',
          value: 'tempo_em_patio',
        },
        {
          text: 'Finalizado Em',
          value: 'chegada_terminal',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Produto',
          value: 'produto',
        },
        {
          text: 'Sub Produto',
          value: 'sub_produto',
        },
        {
          text: 'Nota fiscal',
          value: 'num_nota_fiscal',
        },
        {
          text: 'Quantidade (KG)',
          value: 'quantidade',
        },
        {
          text: 'Embalagem',
          value: 'embalagem',
        },
        {
          text: 'Modalidade',
          value: 'modalidade',
        },
        {
          text: 'Eixos',
          value: 'eixos',
        },
      ],
      carregaDetalhes: false,
      type: 'week',
      types: [
        { text: 'Mês', value: 'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Dia', value: 'day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [
        DAYS.DOMINGO,
        DAYS.SEGUNDA,
        DAYS.TERCA,
        DAYS.QUARTA,
        DAYS.QUINTA,
        DAYS.SEXTA,
        DAYS.SABADO,
      ],
      weekdays: [
        {
          text: 'Domingo - Sábado',
          value: [
            DAYS.DOMINGO,
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
            DAYS.SABADO,
          ],
        },
        {
          text: 'Segunda - Sexta',
          value: [
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
          ],
        },
      ],
      value: '',
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      tab: null,
      editedIndex: -1,
      defaultItem: {
        public_id: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        transportadora: null,
      },
      editedItem: {
        public_id: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        transportadora: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['user', 'empresaAtual']),
    ...mapState('programacao', [
      'params',
      'programacaoRegiao',
      'programacoesRegiao',
    ]),
    ...mapState('transportadoras', ['transportadoras']),
    editavel() {
      if (this.programacaoRegiao) {
        if (
          new Date(
            this.programacaoRegiao.programacao_fornecedor.programacao_unidade.data_fim
          ) > new Date()
        ) {
          return true
        }
      }
      return false
    },
    suspendivel() {
      if (this.editedIndex >= 0) {
        if (new Date(this.editedItem.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
    notSuspended() {
      if (this.programacaoRegiao) {
        return (
          this.programacaoRegiao.programacao_fornecedor.programacao_unidade
            .suspendido == 0
        )
      }
      return true
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.editedIndex === -1
        ? 'Distribuir nova cota'
        : 'Editar cota distribuida'
    },
    permission() {
      return Sequences.CotasCliente.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    this.date2iso = date2iso
    // await this.atualizarListaProgramacoesRegiao()
  },
  watch: {},
  methods: {
    ...mapActions('patio', [
      'getTransportadoras',
      'getPedidos',
      'deleteProgramacaoCliente',
    ]),
    ...mapActions('programacao', [
      'getProgramacoesRegiao',
      'getProgramacaoRegiao',
      'getProgramacoesCliente',
      'addProgramacaoCliente',
      'editProgramacaoCliente',
      'getProgramacaoDetalhe',
    ]),
    ...mapActions('transportadoras', ['getTransportadoras']),
    ...mapMutations('programacao', ['SET_PROGRAMACAO_REGIAO']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async detalhesDistribuicao(item) {
      // try {
      //   this.carregaDetalhes = true
      //   const data = await this.getProgramacaoDetalhe({
      //     type: 'Regiao',
      //     public_id: item.public_id,
      //   })
      //   this.detalhes = data
      //   this.detalhar = true
      // } catch (error) {
      //   this.errorMessage(
      //     'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
      //   )
      // } finally {
      //   this.carregaDetalhes = false
      // }
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Regiao',
          public_id: item.public_id,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.download.type = 'Regiao'
        this.download.public_id = item.public_id
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },
    addProgramacao() {
      this.programacaoRegiao.programacoes_cliente.push({
        ...this.editedItem,
      })
      Object.assign(this.editedItem, this.defaultItem)
    },
    async removeProgramacao(index) {
      let programacao = this.programacaoRegiao.programacoes_cliente[index]
      if (programacao.public_id != '') {
        try {
          await this.deleteProgramacaoCliente(programacao)
          this.programacaoRegiao.programacoes_cliente.splice(index, 1)
        } catch (error) {
          if (error.response) {
            if (error.response.data) {
              const data = error.response.data
              if (data.error_quantidade) {
                this.errorMessage(data.error_quantidade)
                if (data.novo_qt_peso) {
                  programacao.qt_peso = data.novo_qt_peso
                }
                if (data.novo_qt_caminhoes) {
                  programacao.qt_caminhoes = data.novo_qt_caminhoes
                }
                return
              }
            }
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
      this.programacaoRegiao.programacoes_cliente.splice(index, 1)
    },
    async saveAll() {
      let errors = []
      await Promise.all(
        this.programacaoRegiao.programacoes_cliente.map(async (programacao) => {
          try {
            let prog_cliente = await this.save(
              {
                ...programacao,
                programacao_regiao: this.programacaoRegiao,
              },
              true
            )
            Object.assign(programacao, prog_cliente)
          } catch (error) {
            errors.push({ programacao: programacao, error: error })
          }
        })
      )
      const map_errors = errors.map((item) => {
        const ret = {}
        let error = null
        if (
          item.error.response &&
          (item.error.response.status == 403 ||
            item.error.response.status == 404)
        ) {
          error = 'Empresa atual não pode cadastrar/editar programações.'
        } else {
          if (item.error.response) {
            error = item.error.response.data
          } else if (item.error.message) {
            error = item.error.message
          } else {
            error = item.error
          }
          if (item.error.response && 'qt_caminhoes' in error) {
            error = error.qt_caminhoes
          }
          if (item.error.response && 'qt_peso' in error) {
            error = error.qt_peso
          }
        }
        const key =
          `${item.programacao.transportadora.business_name}` +
          ' - ' +
          `${
            item.programacao.transportadora.cnpj != null
              ? item.programacao.transportadora.cnpj
              : item.programacao.transportadora.cpf
          }`
        ret[key] = error
        return ret
      })
      if (map_errors.length > 0) {
        this.errorMessage(map_errors)
      }
    },
    async save(programacao, skip_errors = false) {
      try {
        let prog_cliente
        if (programacao.public_id != '') {
          prog_cliente = await this.editProgramacaoCliente(programacao)
          if (this.replicar.length > 0) {
            await this.editarReplicas(
              prog_cliente,
              this.editProgramacaoCliente,
              this.addProgramacaoCliente
            )
          }
        } else {
          prog_cliente = await this.addProgramacaoCliente(programacao)
          if (this.replicar.length > 0) {
            await this.cadastrarReplicas(
              prog_cliente,
              this.addProgramacaoCliente
            )
          }
        }
        this.SET_PROGRAMACAO_REGIAO(this.programacaoRegiao)
        this.close()
        return prog_cliente
      } catch (error) {
        if (skip_errors) {
          return Promise.reject(error)
        }
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage(
            'Empresa atual não pode cadastrar/editar programações.'
          )
        } else {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async editItem(item) {
      this.replicar = []
      this.editReplicas(item, this.programacoesRegiao)
      this.SET_PROGRAMACAO_REGIAO(item)
      await this.getTransportadoras({ public_id: item.cliente.cliente_id })
    },
    close() {
      this.selectedOpen = false
    },
    async atualizarListaProgramacoesRegiao(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        if (this.empresaAtual.representantes.length > 0) {
          const selectedAtLeastOneRegion = params?.regiao?.public_id
          if (!selectedAtLeastOneRegion) {
            alert('Necessário selecionar região para buscar os dados.')
            return
          }
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        await this.getProgramacoesRegiao(filtros)
        this.events = []
        this.getEvents()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    createName(programacao) {
      let name = ''
      name += programacao.programacao_fornecedor.programacao_unidade.fornecedor
        ? programacao.programacao_fornecedor.programacao_unidade.fornecedor
            .trade_name
          ? programacao.programacao_fornecedor.programacao_unidade.fornecedor
              .trade_name
          : programacao.programacao_fornecedor.programacao_unidade.fornecedor
              .fornecedor_trade_name
        : ''
      if (programacao.programacao_fornecedor.programacao_unidade.produto) {
        name +=
          ' - ' +
          programacao.programacao_fornecedor.programacao_unidade.produto
            .descricao
      }
      if (programacao.programacao_fornecedor.programacao_unidade.sub_produto) {
        name +=
          ' - ' +
          programacao.programacao_fornecedor.programacao_unidade.sub_produto
            .descricao
      }
      // if (programacao.programacao_fornecedor.programacao_unidade.qt_caminhoes) {
      //   name +=
      //     ' - ' +
      //     programacao.programacao_fornecedor.programacao_unidade.qt_caminhoes +
      //     ' Cam.'
      // }
      // if (programacao.programacao_fornecedor.programacao_unidade.qt_peso) {
      //   name +=
      //     ' - ' +
      //     programacao.programacao_fornecedor.programacao_unidade.qt_peso +
      //     ' Ton.'
      // }
      return name
    },
    getEvents() {
      this.programacoesRegiao.forEach((element) => {
        let i = this.events.findIndex(
          (event) => event.item.public_id == element.public_id
        )
        let start = new Date(
          element.programacao_fornecedor.programacao_unidade.data_inicio
        )
        let end = new Date(
          element.programacao_fornecedor.programacao_unidade.data_fim
        )
        if (i == -1) {
          this.events.push({
            item: element,
            name: this.createName(element),
            color:
              element.programacao_fornecedor.programacao_unidade.suspendido == 1
                ? 'red'
                : element.programacao_fornecedor.programacao_unidade
                    .tipo_operacao
                ? element.programacao_fornecedor.programacao_unidade
                    .tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          })
        } else {
          this.events[i] = {
            item: element,
            name: this.createName(element),
            color:
              element.programacao_fornecedor.programacao_unidade.suspendido == 1
                ? 'red'
                : element.programacao_fornecedor.programacao_unidade
                    .tipo_operacao
                ? element.programacao_fornecedor.programacao_unidade
                    .tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          }
        }
      })
    },
    setToday() {
      this.value = ''
    },
    async showEvent({ nativeEvent, event }) {
      if (this.canUpdate) {
        const open = async () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          const item = await this.getProgramacaoRegiao(event.item.public_id)
          this.SET_PROGRAMACAO_REGIAO(item)
          this.editItem(event.item)
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(async () =>
            requestAnimationFrame(async () => await open())
          )
        } else {
          await open()
        }

        nativeEvent.stopPropagation()
      }
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color
    },
  },
}
</script>
